// --------------------------------------------------
// Block
// --------------------------------------------------

.site-block {
  padding-top: 45px;
  padding-bottom: 45px;

  @media (max-width: 767px) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.video001 {
  display: block;
  margin-top: 70px;
  margin-bottom: 70px;

  @media(max-width: 725px) {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    width: calc(100% - 40px);
    height: 0;

  }

  iframe {
    display: block;
    max-width: 745px;
    max-height: 420px;
    margin: 0 auto;

    @media(max-width: 725px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: inherit;
      max-height: inherit;
    }
  }
}

// --------------------------------------------------
// About nav
// --------------------------------------------------

.blocks-nav {
  @extend .site-max-width;
  @extend .site-block;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .blocks-nav-link {
    padding: 10px 15px;
    margin: 0 10px;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    background-color: #4c4c4c;
    transition: .5s;
    cursor: pointer;

    &.link-0:hover, &.link-0.is-active {
      background-color: $colorM1;
    }

    &.link-1:hover, &.link-1.is-active {
      background-color: $colorM2;
    }

    &.link-2:hover, &.link-2.is-active {
      background-color: $colorM3;
    }

    &.link-3:hover, &.link-3.is-active {
      background-color: $colorM4;
    }

    &.link-4:hover, &.link-4.is-active {
      background-color: $colorM5;
    }

    &.link-5:hover, &.link-5.is-active {
      background-color: $colorM6;
    }

    &.link-6:hover, &.link-6.is-active {
      background-color: $colorM7;
    }

    &.link-7:hover, &.link-7.is-active {
      background-color: $colorM8;
    }

    &.link-8:hover, &.link-8.is-active {
      background-color: $colorM9;
    }

    &.link-9:hover, &.link-9.is-active {
      background-color: $colorM10;
    }

    &.link-10:hover, &.link-10.is-active {
      background-color: $colorM11;
    }

    &.link-11:hover, &.link-11.is-active {
      background-color: $colorM12;
    }

    &.link-12:hover, &.link-12.is-active {
      background-color: $colorM13;
    }

    &.link-13:hover, &.link-13.is-active {
      background-color: $colorM14;
    }

    &.link-14:hover, &.link-14.is-active {
      background-color: $colorM15;
    }


  }
}

// --------------------------------------------------
// Blocks Arguments001
// --------------------------------------------------

.arguments001 {
  @extend .site-block;

  .arguments001-wrapper {
    @extend .site-max-width;
    display: flex;
    flex-wrap: wrap;

    .grid-item {
      width: 25%;
      text-align: center;
      border-right: 1px solid $lighter;

      @media(max-width: 800px) {
        width: 50%;
        padding-bottom: 20px;
        border-right: none;

        &:first-child, &:nth-child(2) {
          // padding-bottom: 20px;
          border-bottom: 1px solid $lighter;
        }

        &:last-child, &:nth-child(3) {
          padding-top: 20px;
        }

        &:first-child {
          border-left: none !important;
        }

        .small-title {
          margin-bottom: 0;
        }
      }

      @media(max-width: 360px) {
        width: 100%;
        padding-top: 20px;
        border-bottom: 1px solid $lighter;

        &:first-child {
          border-top: 1px solid $lighter;
        }
      }

      &:first-child {
        border-left: 1px solid $lighter;
      }
    }

    .grid-item-stats, .hasMore {
      font-size: 38px;
      margin: 0;
      display: inline-block;
    }

    .small-title {
      text-transform: uppercase;
    }
  }
}

// --------------------------------------------------
// Blocks grid004
// --------------------------------------------------

.grid004 {

  .grid004-wrapper {
    @extend .site-max-width;
  }

  .grid004-heading {
    text-align: center;
    font-size: 48px;
    font-weight: 400;
  }

  .grid004-container {
    display: flex;
    flex-wrap: wrap;

    .grid004-row {
      display: flex;
      flex-wrap: wrap;
      width: 50%;
      align-self: flex-start;

      @media(max-width: 1024px) {
        width: 100%;
      }

      .grid004-article {
        margin: 15px;
        padding: 15px;
        width: calc(50% - (15px * 2));
        background-size: cover;
        background-position: center;

        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, .45);
          z-index: 1;
        }

        @media(max-width: 500px) {
          width: 100%;
        }

        .grid004-article-heading {

        }

        .regular-text {
          color: #fff;
        }

        .article-content-wrapper {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #fff;
          z-index: 2;
        }

        &.article-small {

        }

        &.article-large {
          width: 100%;
          min-height: 500px;
          text-align: center;

          @media(max-width: 500px) {
            min-height: 0;
            text-align: left;
          }

          .article-content-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

// --------------------------------------------------
// Blocks Cta001
// --------------------------------------------------

.cta001 {
  @extend .site-block;;
  border-top: 1px solid #3fa2f7;
  border-bottom: 1px solid #3fa2f7;
  color: #3fa2f7;

  .cta001-wrapper {
    @extend .site-max-width;
    position: relative;

    .cta001-heading {
      margin-top: 0;
      text-align: center;
      font-size: 48px;
      font-weight: 400;
      text-transform: uppercase;
    }

    .cta001-text {
      max-width: 920px;
      height: 90px;
      margin: 0 auto;
      overflow: hidden;
      line-height: 2;
      text-align: center;
      font-weight: 400;
    }

    .cta001-btn-container {
      width: 100px;
      margin: 0 auto;
    }

    .site-btn-alternate {
      text-align: center;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

// --------------------------------------------------
// Blocks cta003
// --------------------------------------------------

.cta003 {
  @extend .site-block;;
  text-align: center;
  color: $dark;

  .cta003-wrapper {
    @extend .site-max-width;

    .cta003-container {
      padding: 60px 0;
      // background-color: rgba(63, 162, 247, .2);
      border-radius: 3px;

      @media(max-width: 600px) {
        padding: 60px 30px;
      }
    }

    .cta003-heading {
      margin-top: 0;
      font-size: 34px;
      font-weight: 400;
    }

    .cta003-text {
      max-width: 600px;
      margin: 0 auto 30px;
      line-height: 1.5;

      p {
        margin: 0;
      }
    }

    // .site-btn {
    //   background-color: rgb(63, 162, 247);
    //   padding: 15px 30px;
    //   font-size: 16px;
    //   border: none;
    //   border-radius: 3px;
    // }
  }
}

// --------------------------------------------------
// Blocks Cta004
// --------------------------------------------------

.cta004 {
  @extend .site-block;;
  border-top: 1px solid #3fa2f7;
  border-bottom: 1px solid #3fa2f7;
  color: #3fa2f7;

  .cta004-wrapper {
    @extend .site-max-width;
    position: relative;

    .cta004-heading {
      margin-top: 0;
      text-align: center;
      font-size: 30px;
      font-weight: 400;
    }

    .cta004-text {
      margin: 0 auto;
      line-height: 1.7;
      text-align: center;
      font-weight: 400;
    }

    .cta004-content-container {
      max-width: 547px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
    }

    .cta004-btn-container {
      width: 100px;
      margin: 0 auto;
    }

    .site-btn {
      margin-top: 30px;
      padding: 15px 20px;
      background-color: rgb(63, 162, 247);
      font-size: 16px;
      border: none;
      border-radius: 3px;
    }
  }
}

// --------------------------------------------------
// Panels Row 001
// --------------------------------------------------

.block-panelsRow001 {
  @extend .site-max-width;

  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0 auto;
  padding-top: 75px;
  padding-bottom: 75px;
  background: #fff;
  text-align: center;

  @media (max-width: 1100px) {
    padding: 55px 0;
  }

  @media (max-width: 699px) {
    padding: 25px 0;
  }

  .panel {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    background: $colorAccent2;

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    .panel-left-col {
      width: 100%;
      max-width: 325px;
      padding: 45px 25px;
      padding-right: 0;
      text-align: center;

      @media (max-width: 699px) {
        padding: 25px 20px;
        padding-bottom: 0;
      }

      h3 {
        margin: 0;
        padding: 0;

        font-size: 22px;
        font-weight: 400;
        text-align: right;
        color: $colorTextLight;
        letter-spacing: 0.052em;
        text-transform: uppercase;

        @media (max-width: 1100px) {
          text-align: center;
        }
      }

      img {
        display: inline-block;
        margin: 0;
      }
    }

    .panel-right-col {
      width: 100%;
      padding: 45px 25px;
      margin: 0;
      text-align: left;
      color: $colorTextLight;
      font-size: 14px;
      line-height: 1.4;
      letter-spacing: 0;

      p {
        margin: 0 0 15px 0;
        padding: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .panel-logo {
      display: block;
      width: 70%;
    }

    &.panel2 {
      background: #fff;

      .panel-left-col {
        h3 {
          color: $colorTextDark;
        }
      }

      .panel-right-col {
        color: $colorTextDark;
      }
    }

    &.panel3 {
      background: $grey;

      .panel-left-col {
        h3 {
          color: $colorTextDark;
        }
      }

      .panel-right-col {
        color: $colorTextDark;
      }
    }

    &.white {
      background: #fff;
      border-top: 2px solid rgba(0, 0, 0, .10);

      &:first-child {
        border-top: none;
      }

      .panel-left-col {
        h3 {
          color: $colorTextDark;
        }
      }

      .panel-right-col {
        color: $colorTextDark;
      }
    }
  }
}

// --------------------------------------------------
// Block Team Grid 001
// --------------------------------------------------

.block-teamGrid001 {
  position: relative;
  display: flex;
  flex-direction: column;

  background: #fff;
  text-align: center;

  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba($grey, 1) 120%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba($grey, 1) 120%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba($grey, 1) 120%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  .big-title {
    padding-left: 20px;
    padding-right: 20px;
  }

  .regular-text {
    max-width: 1125px;
    padding-left: 20px;
    padding-right: 20px;
    color: $colorTextDark;

    p {
      margin-bottom: 75px;

      @media (max-width: 1100px) {
        margin-bottom: 55px;
      }

      @media (max-width: 699px) {
        margin-bottom: 25px;
      }
    }
  }

  .team-grid-cards {
    @extend .site-max-width;

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 75px;
    padding-bottom: 75px;
    text-align: left;

    @media (max-width: 1100px) {
      padding-top: 45px;
      padding-bottom: 45px;
    }

    .regular-text,
    h3 {
      font-size: 14px;
      color: $dark;
      font-weight: 400;
      margin: 0 0 20px 0;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .regular-text {
      p {
        padding: 0;
        margin: 0;
      }
    }

    .team-card {
      position: relative;
      display: block;
      width: 100%;
      max-width: 31.5%;
      margin-bottom: 45px;
      background: #fff;

      overflow: hidden;

      @media (max-width: 1100px) {
        max-width: 48%;
      }

      @media (max-width: 699px) {
        max-width: 100%;
        margin-bottom: 20px;
      }

      .team-img,
      .image {
        display: block;
        width: 101%;
        max-height: 500px;
      }

      .content {
        padding: 20px 20px;
        min-height: 320px;
      }
    }
  }

  .team-foot-text {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 100px;
    font-size: 30px;
    font-weight: 200;
    line-height: 2;
    letter-spacing: -0.025em;
    color: #fff;

    @media (max-width: 699px) {
      font-size: 18px;
      line-height: 1.4;
      padding-bottom: 40px;
      margin-top: -40px;

      br {
        display: none;
      }
    }
  }
}

// --------------------------------------------------
// Block Team Grid 002
// --------------------------------------------------

.teamGrid002 {
  @extend .site-block;;

  @media(max-width: 420px) {
    padding: 20px 0;
  }

  .teamGrid002-wrapper {
    @extend .site-max-width;

    .teamGrid002-heading {
      font-size: 48px;
      font-weight: 400;
      text-align: center;
    }

    .teamGrid002-grid {
      display: flex;
      flex-wrap: wrap;
    }

    .teamGrid002-grid-items {
      width: calc(33.33% - (10px * 2));
      margin: 10px;
      text-align: center;

      &:first-child, &:nth-child(3n+1) {
        margin-left: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      @media(max-width: 650px) {
        width: 100%;
      }

      .grid-items-image {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .grid-items-heading {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0;
      }

      .grid-items-position {
        min-height: 36px;
        margin-top: 10px;
        font-size: 14px;
        color: #90A0B0;

        @media(max-width: 650px) {
          min-height: 0;
          margin-bottom: 0;
        }
      }

      .regular-text {
        @media(max-width: 650px) {
          font-size: 16px;

          p {
            margin: 5px;
          }
        }
      }
    }
  }
}

// --------------------------------------------------
// Block Team Grid 002
// --------------------------------------------------

.teamGrid003 {
  @extend .site-block;;
  color: rgb(63, 162, 247);

  .teamGrid003-wrapper {
    @extend .site-max-width;

    .teamGrid003-carousel {
      width: 100%;
    }

    .flickity-viewport {
      height: 500px;
    }

    .teamGrid003-carousel-items {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
    }

    .teamGrid003-image-wrapper {
      width: calc(50% - (40px * 2));
      margin-right: 40px;
      background-color: rgba(63, 162, 247, .2);

      @media(max-width: 1380px) {
        margin-right: 20px;
      }

      @media(max-width: 780px) {
        width: 100%;
        margin-right: 0;
      }

      img {
        width: 100%;
        display: block;
        margin: 0 auto;
      }
    }

    .teamGrid003-text-wrapper {
      width: 50%;
      margin-left: 40px;
      padding: 0 30px;

      @media(max-width: 1380px) {
        margin-left: 20px;
        padding: 0;
      }

      @media(max-width: 780px) {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;

        .teamGrid003-member-jobTitle {
          margin: 0 0 20px 0;
        }
      }

      .teamGrid003-member-heading {
        margin: 0 0 10px 0;
        font-weight: 400;
        font-size: 52px;
        line-height: 58px;
      }

      .teamGrid003-member-jobTitle {
        margin: 0 0 20px 0;
      }

      .teamGrid003-member-bio {
        line-height: 1.8;
      }
    }
  }

  .teamGrid003-sliderNav {
    margin-top: 60px;
    background-color: rgba(63, 162, 247, .2);

    .teamGrid003-sliderNav-wrapper {
      @extend .site-max-width;
      position: relative; //    padding: 30px 0;

      // @media(max-width: 600px){
      //   padding: 30px 0;
      // }
    }

    .teamGrid003-sliderNav-carousel {
      position: relative;
    }

    .flickity-viewport {
      max-height: 160px;
      top: -13px;
      padding-top: 15px;
    }

    .is-selected-arrow {
      position: absolute;
      top: -7px;
      left: 176px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid rgba(63, 162, 247, .2);
    }

    .slider-item {
      width: 16.667%;
      padding: 20px 60px;

      @media(max-width: 1130px) {
        width: 20%;
      }

      @media(max-width: 990px) {
        width: 25%;
        padding: 20px 40px;
      }

      @media(max-width: 600px) {
        width: 33.33%;
        padding: 10px 30px;
      }

      &.is-selected {
        &:before {
          content: '';
          position: absolute;
          top: -15px;
          left: 50%;
          margin-left: -8px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 13px 13px 13px;
          border-color: transparent transparent rgba(63, 162, 247, .2) transparent;
        }
      }

      img {
        width: 100%;
        border-radius: 100%;
      }
    }
  }
}

// --------------------------------------------------
// Timeline 001
// --------------------------------------------------

.block-timeline001 {
  padding: 0;
  margin: 0;

  .timeline-wrapper {
    background-image: linear-gradient(to top, $colorAccent1, $colorTextDark, $colorAccent2 70%);
  }

  .timeline-content {
    @extend .site-max-width;

    display: block;
    width: 100%;

    padding-top: 75px;

    @media (max-width: 1100px) {
      padding-top: 45px;
    }

    .timeline-date {
      @extend .main-text;
    }

    .timeline-title {
      @extend .title4;
      margin-bottom: 15px;
      color: #fff;

      @media (max-width: 699px) {
        margin-bottom: 10px;
      }
    }

    .timeline-desc {
      @extend .main-text;

      font-size: 20px;
      line-height: 1.4;
      font-weight: 700;
      color: #fff;

      p {
        color: #fff;
      }

      @media (max-width: 699px) {
        margin-bottom: 10px;
      }
      @media(max-width: 1050px) {
        font-size: 16px;
      }
    }
  }

  .timeline-row {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 40px;

    @media (max-width: 699px) {
      flex-direction: column;
      padding-left: 60px;
      padding-bottom: 40px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      width: 4px;
      height: 100%;
      margin-left: -1.5px;
      background-color: $white;

      @media (max-width: 699px) {
        left: 10px;
        margin-left: 2.5px;
      }
    }

    &:first-of-type {
      &:before {
        top: calc(50% - 35px);
        height: calc(50% + 35px);
      }
    }

    &:last-of-type {
      &:before {
        height: calc(50% - 35px);
      }
    }

    .timeline-row-img {
      width: calc(50% - 60px);

      @media (max-width: 699px) {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    .timeline-row-dot {
      position: relative;
      margin: 0 45px;

      @media (max-width: 699px) {
        position: absolute;
        left: 0;
        top: calc(50% - 80px);
        width: 24px;
        height: 24px;
        border-width: 3px;
        margin: 0;
      }
    }

    .timeline-row-content {
      color: #fff;
      width: calc(50% - 60px);

      @media (max-width: 699px) {
        width: 100%;
      }
    }

    &:nth-child(odd) {
      .timeline-row-img {
        order: 3;

        @media (max-width: 699px) {
          order: 2;
        }
      }

      .timeline-row-dot {
        order: 2;

        @media (max-width: 699px) {
          order: 1;
        }
      }

      .timeline-row-content {
        order: 1;
        text-align: left;

        @media (max-width: 699px) {
          order: 3;
          text-align: left;
        }
      }
    }
  }

  %clearfix {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  /* Loader 2 */
  .loader-container {
    position: relative;
    width: 100%;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    margin: 0px auto;
    width: 200px;
  }

  .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  @keyframes color {
    100%, 0% {
      stroke: #E1E1E1;
    }
    40% {
      stroke: #CCCCCC;
    }
    66% {
      stroke: #AEAEAE;
    }
    80%, 90% {
      stroke: #CCCCCC;
    }
  }

  .beacon-container {
    position: relative;
  }

  .beacon {
    width: 30px;
    height: 30px;
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);

    &:after,
    &:before {
      position: absolute;
      display: block;
      background-color: transparent;
      transform-origin: 50% 50%;
    }

    &:after,
    &:before {
      content: '';
      top: -1px;
      left: -1px;
      border: 1px solid $white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      transform: scale(1.3, 1.3);
    }

    &:before {
      animation: pulse 2s infinite;
    }

    &:after {
      animation: pulse 2s infinite .7s;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @-moz-keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @-o-keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }


}

// --------------------------------------------------
// Block pricing001
// --------------------------------------------------

.pricing001 {
  @extend .site-block;;

  @media(max-width: 420px) {
    padding: 20px 0;
  }

  .pricing001-wrapper {
    @extend .site-max-width;
    text-align: center;

    .pricing001-heading {
      font-size: 48px;
      font-weight: 400;
    }

    .pricing001-grid {
      display: flex;
      flex-wrap: wrap;

      .pricing001-grid-items {
        width: 33.33%;
        padding: 35px 40px;
        border: 2px solid rgba(0, 0, 0, .1);

        &:first-child, &:nth-child(2) {
          border-right: none;
        }

        @media(max-width: 855px) {
          width: 100%;

          &:first-child, &:nth-child(2) {
            border-bottom: none;
            border-right: 2px solid rgba(0, 0, 0, .1);
          }
        }
      }

      .grid-items-header {
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);

        @media(max-width: 855px) {
          padding-bottom: 0;
        }
      }

      .grid-items-heading {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 400;
      }

      .grid-items-shortDescription {
        margin: 0;
        font-size: 14px;
      }

      .grid-items-description {
        margin-bottom: 0;
        font-size: 14px;

        p {
          min-height: 72px;
          margin-bottom: 0;
        }
      }

      .grid-items-price {
        font-weight: 400;
        font-size: 60px;
        margin: 30px 0;
      }

      .grid-items-list {
        padding: 0;
        list-style: none;
      }

      .grid-items-list-items {
        padding: 15px 0;
      }

      .site-btn {
        padding: 10px 35px;
        font-size: 16px;
      }
    }
  }
}

// --------------------------------------------------
// Block pricing002
// --------------------------------------------------

.pricing002 {
  padding: 20px 0;
  color: rgb(63, 162, 247);

  .pricing002-wrapper {
    @extend .site-max-width;

    .pricing002-heading {
      font-size: 48px;
      font-weight: 400;
      text-align: center;
    }

    .pricing002-grid {
      display: flex;
      flex-wrap: wrap;
    }

    .grid-items {
      width: calc(50% - (20px * 2));
      margin: 10px;
      padding: 20px;
      border: 1px solid rgba(63, 162, 247, .1);

      @media(max-width: 800px) {
        width: 100%;
      }

      &:nth-child(odd) {
        margin-left: 0;
      }

      &:nth-child(even) {
        margin-right: 0;
      }

      .grid-items-heading {
        display: inline-block;
      }

      .grid-items-pricing {
        display: inline-block;
        float: right;
      }

      .pricing-bar-container {
        position: relative;
        width: 100%;

        &:focus {
          outline: 0;
        }

        .value-bar {
          position: absolute;
          top: 2px;
          width: 35%;
          height: 10px;
          display: block;
          z-index: 1;
          background-color: rgb(63, 162, 247);

          &:focus {
            outline: 0;
          }
        }

        input[type="range"] {
          -webkit-appearance: none;
          width: 100%;
          height: 10px;
          position: absolute;
          top: 2px;
          background-color: rgba(63, 162, 247, .1);
          z-index: 5;

          &:focus {
            outline: 0;
          }
        }

        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: rgb(63, 162, 247);
          position: relative;
          z-index: 3;
          cursor: pointer;

          &:focus {
            outline: 0;
          }
        }
      }

      .pricing-bar-values {
        display: flex;
        margin-top: 20px;

        span {
          position: relative;
          width: 20%;
          margin-top: 10px;
          margin-right: 5px;
          text-align: right;

          &:before {
            position: absolute;
            top: -13px;
            content: "|";
            font-size: 10px;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }


    }

    .pricing002-total-container {
      .pricing002-total-price {
        font-size: 38px;
        text-align: center;
      }
    }
  }
}

// --------------------------------------------------
// Split text image
// --------------------------------------------------

.block-split-text-image {
  display: flex;
  padding: 0 0 0 0;
  border-bottom: 1px solid $lighter;

  @media only screen and (max-width: 599px) {
    flex-wrap: wrap;
  }

  &.is-left {
    flex-direction: row-reverse;
  }

  .block-content {
    position: relative;
    width: 75%;
    padding: 75px;

    @media only screen and (max-width: 767px) {
      padding: 20px;
    }

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    &.is-collapsed {
      &:after {
        content: '';
        position: absolute;
        top: 65%;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
        z-index: 2;
      }
    }

    .regular-text {
      // margin-bottom: 75px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    h2 {
      @extend .big-title;
      //color: $dark;
      line-height: 1.4;

      @media only screen and (max-width: 767px) {
        font-size: 22px;
        margin: 0;
        padding: 0;
      }
    }

    h3 {
      @extend .big-title;
      color: $dark;
      font-size: 26px;
      line-height: 1.4;
    }

    h4 {
      @extend .big-title;
      color: $dark;
      font-size: 20px;
      line-height: 1.4;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    figure {
      padding: 0;
      margin: 0;
    }

    blockquote {
      padding: 0 0 0 25px;
      margin: 25px 0;
      border-left: 4px solid $lighter;
    }

    .read-more {
      display: none;
      position: absolute;
      right: 75px;
      bottom: 25px;
      z-index: 3;
      padding: 5px 0;
      font-size: 14px;
    }
  }

  .block-image {
    width: 25%;

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    img {
      display: block;
    }
  }
}

// --------------------------------------------------
// Block - Image
// --------------------------------------------------

.block-image {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

// --------------------------------------------------
// Block - Partners
// --------------------------------------------------

.block-partners {
  padding: 0;

  .grid-text {
    width: 100%;
    text-align: center;

    @media only screen and (max-width: 767px) {
    }
  }

  .big-title {
    position: relative;
    width: 100%;
    text-align: center;
    font-family: $fontTitle;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0 0 50px;
    color: black;

    @media (max-width: 650px) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 1.5px;
      margin-bottom: 25px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 9px;
      width: 100%;
      height: 1px;
      background-color: rgba(#262626, .2);
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%);
      width: 60px;
      height: 4px;
      background-color: $colorAccent1;

      @media (max-width: 650px) {
        height: 2px;
        bottom: -10px;
      }
    }

    span {
      position: relative;
      z-index: 2;
      padding: 0 15px;
      background-color: $grey;

      &.white-bg {
        background-color: $white;
      }
    }
  }

  .partners-cta {
    background-color: $grey;
    padding: 80px 20px;

    @media (max-width: 767px) {
      padding: 40px 20px;
    }

    .partners-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1520px;
      margin: 0 auto;

      &.page {
        .partners-list {
          &:not(.big) {
            margin-bottom: 40px;
          }

          .partial-partner {
            margin-bottom: 40px;
          }
        }
      }
    }

    .partners-list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 1100px) {
        flex-wrap: wrap;
      }

      @media (max-width: 650px) {
        flex-direction: column;
      }

      &.big {
        .partial-partner {
          width: calc(50% - 10px);
          min-height: 250px;

          @media (max-width: 1100px) {
            width: calc(50% - 10px);
            margin-bottom: 20px;
            min-height: 170px;
          }

          @media (max-width: 650px) {
            width: 100%;
            min-height: 140px;
          }
        }
      }
    }

    .partial-partner {
      width: calc(25% - ((20px * 3) / 4));
      height: 170px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      @media (max-width: 1100px) {
        width: calc(50% - 10px);
        margin-bottom: 20px;
      }

      @media (max-width: 650px) {
        width: 100%;
        min-height: 140px;
      }

      img {
        width: 70%;
        max-width: 70%;
      }
    }

    .site-btn {
      margin-top: 50px;

      @media (max-width: 1100px) {
        margin-top: 30px;
      }
    }
  }
}

// --------------------------------------------------
// Block - Testimonials001
// --------------------------------------------------

.testimonials {
  @extend .site-block;;
  color: #3fa2f7;

  .testimonials-heading-wrapper {
    margin-bottom: 60px;
  }

  .testimonials-heading {
    margin-top: 0;
    text-align: center;
    font-size: 52px;
    font-weight: 300;
    line-height: 58px;
  }

  .testimonials-slider {
    .flickity-viewport {
      height: 380px;
    }

    .is-selected {
      opacity: 1 !important;

      .slider-skeleton, .slider-skeleton p {
        color: inherit !important;
        background: linear-gradient(to top, transparent, transparent) repeat-x !important;
      }
    }

    .testimonials-slider-item {
      width: 100%;
      max-width: 524px;
      margin: 0 auto;
      padding: 0 10px;
      opacity: .5;
      border-radius: 3px;
      color: #3fa2f7;

      .testimonials-cards {
        padding: 50px;
        line-height: 25px;
        border: 1px solid #e4f1fd;
      }

      .testimonials-item-heading {
        font-size: 19px;
        font-weight: 400;
        margin: 0;

        .slider-skeleton {
          color: transparent;
          /* background: -webkit-gradient(linear, left bottom, left top, from(#3fa2f7), to(#3fa2f7)) repeat-x; */
          background: linear-gradient(to top, #3fa2f7, #3fa2f7) repeat-x;
          background-size: 1px 12px;
          background-position: center;
          -webkit-transition: 0.5s ease all;
          transition: 0.5s ease all;
        }
      }

      .testimonials-infos {
        display: inline-block;
        font-size: 14px;
      }

      .testimonials-rating {
        display: inline-block;

        // .testimonials-rating-items{
        //   display: block;
        //   background-color: #3fa2f7;
        //   width: 20px;
        //   height: 20px;
        // }
      }

      .testimonials-item-date {
        margin: 0;

        .slider-skeleton {
          color: transparent;
          background: -webkit-gradient(linear, left bottom, left top, from(#3fa2f7), to(#3fa2f7)) repeat-x;
          background: linear-gradient(to top, #3fa2f7, #3fa2f7) repeat-x;
          background-size: 1px 12px;
          background-position: center;
          -webkit-transition: 0.5s ease all;
          transition: 0.5s ease all;
        }
      }

      .testimonials-text {
        margin: 10px 0 0 0;

        .slider-skeleton {
          p {
            display: inline;
            color: transparent;
            background: -webkit-gradient(linear, left bottom, left top, from(#3fa2f7), to(#3fa2f7)) repeat-x;
            background: linear-gradient(to top, #3fa2f7, #3fa2f7) repeat-x;
            background-size: 1px 12px;
            background-position: center;
            -webkit-transition: 0.5s ease all;
            transition: 0.5s ease all;
          }

        }
      }
    }
  }

  .flickity-prev-next-button {
    @media(min-width: 650px) {
      display: none;
    }

    .flickity-button-icon {
      fill: #3fa2f7;
    }
  }
}

// --------------------------------------------------
// Block - Testimonials002
// --------------------------------------------------

.testimonials002 {
  @extend .site-block;;
  max-width: 1100px;
  margin: 0 auto;
  color: #3fa2f7;

  .testimonials002-wrapper {
    @extend .site-max-width;
  }

  .testimonials002-title {
    text-align: center;
    margin-top: 0;
    text-align: center;
    font-size: 52px;
    font-weight: 300;
    line-height: 58px;
  }

  .testimonials002-slider {
    width: 100%;
    margin: 0 auto 40px;
    text-align: center;

    .flickity-viewport {
      height: 200px;
    }

    .flickity-button-icon {
      width: 50%;
      height: 50%;
      fill: #3fa2f7;
    }

    .flickity-prev-next-button {
      top: 35%;

      &.previous {
        left: 0;
      }

      &.next {
        right: 0;
      }
    }

    .testimonials002-slider-item {
      width: 100%;

      .testimonials002-text {
        display: inline-block;
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
        line-height: 2;
        font-size: 18px;

        @media(max-width: 440px) {
          width: 80%;
        }
      }
    }
  }

  .testimonials002-sliderNav {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;

    .is-selected {
      opacity: 1 !important;
    }

    .slider-item {
      width: 25%;
      text-align: center;
      opacity: .5;

      .slider-item-heading {
        font-weight: 400;
      }

      img {
        width: 50px;
        margin-left: 0 auto;
      }
    }
  }
}



.produit-listing-wrapper {
  @extend .site-max-width;

  .produit-listing-container {

    .product-wrapper {
      display: flex;
      margin-bottom: 150px;

      &:nth-child(odd){
        flex-direction: row-reverse;
      }

      @media(max-width: 1024px){
        flex-direction: column;
        margin-bottom: 150px;
      }

      @media(max-width: 764px){
        margin-bottom: 50px;
      }

      .product-infos {
        .product-title {
          @extend .medium-title;
        }

        .product-origin {
          @extend .small-text;
        }

        .product-text {
          @extend .regular-text;
        }
      }

      .product-image {
        display: block;
        width: 40%;
        margin-right: 40px;

        @media(max-width: 764px){
          width: 100%;
          margin-right: 0;
        }

      }
    }
  }
}
