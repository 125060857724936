.children {
    // TITLES & TEXTS
    @function rem($size) {
        @return $size / 16px * 1rem;
    }

    .title-separator {
        display: block;
        height: 4px;
        width: 135px;
        margin: 0 auto;
        padding: 0;
        background-color: $colorAccent1;
    }

    .bloc-children-hero {
        position: relative;
        width: 100%;
        background: $white;
        padding: 130px 20px 250px;

        .points-left {
            display: block;
            position: absolute;
            top: 40px;
            left: 40px;
            width: 100%;
            max-width: 363px;
            z-index: 1;
        }

        .points-right {
            display: block;
            position: absolute;
            right: 40px;
            bottom: 40px;
            width: 100%;
            max-width: 363px;
            z-index: 1;
        }

        .arcenciel {
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            max-width: 420px;
            z-index: 1;
        }

        .coeur {
            display: block;
            position: absolute;
            top: 100px;
            right: 100px;
            width: 100%;
            max-width: 105px;
            z-index: 1;
        }

        .children-hero-wrapper {
            position: relative;
            border-radius: 8px;
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
            background-color: $white;
            z-index: 2;

            .children-hero-title {
                @extend .big-title;
                text-align: center;

                width: 100%;
                max-width: 600px;
                margin: 0 auto;

                margin-bottom: 30px;
            }

            .children-hero-text {
                text-align: center;
                font-family: $fontText;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.005em;

                color: #90A0B0;

                width: 100%;
                max-width: 600px;
                margin: 0 auto;
                margin-top: 50px;
            }

            .children-hero-reasons-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                width: 100%;
                max-width: 749px;
                margin: 0 auto;
                margin-top: 70px;

                @media (max-width: 764px) {
                    flex-direction: column;
                    justify-content: center;
                }

                .reason-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    max-width: calc((100% / 3) - 30px);
                    min-height: 270px;

                    @media (max-width: 764px) {
                        margin-bottom: 20px;
                        max-width: 100%;
                    }

                    .reason-icon {
                        display: block;
                        height: 100%;
                        max-height: 80px;
                    }

                    .reason-title {
                        font-family: $fontTitle;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 26px;
                        line-height: 31px;

                        display: flex;
                        align-items: center;
                        text-align: center;

                        color: #262626;

                        margin-top: 25px;
                        margin-bottom: 5px;
                    }

                    .reason-text {
                        font-family: $fontText;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.005em;

                        color: #90A0B0;
                        margin: 0;
                    }
                }
            }
            
        }
    }

    .bloc-children-partners {
        position: relative;
        width: 100%;
        padding: 120px 20px 70px;
        background: #F7F2F0;

        .children-partners-wrapper {
            width: 100%;
            max-width: 1070px;
            margin: 0 auto;

            .partners-title {
                @extend .big-title;
                text-align: center;
            }

            .partners-logos-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 70px;

                @media (max-width: 764px) {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }

                .partner-link {
                    display: block;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    width: 100%;
                    max-width: 150px;

                    @media (max-width: 764px) {
                        max-width: calc((100% / 2) - 10px);

                        &:nth-child(2n) {
                            margin-right: 0;
                        }
                    }
    

                    &:last-child {
                        margin-right: 0;
                    }

                    img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }

    .bloc-tiktok {
        background-color: #3B3B3B;
        padding: 0 20px;

        @media (max-width: 764px) {
            padding: 20px;
        }

        .tiktok-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: calc(879px + 20px);
            margin: 0 auto;

            @media (max-width: 764px) {
                flex-wrap: wrap;
            }

            .tiktok-image-wrapper {
                display: block;
                width: 100%;
                max-width: 520px;

                @media (max-width: 764px) {
                    max-width: 100%;
                }

                img {
                    display: block;
                    width: 100%;
                }
            }

            .tiktok-content-wrapper {
                display: block;
                width: 100%;
                max-width: 304px;

                @media (max-width: 764px) {
                    max-width: 100%;
                }

                .tiktok-title {
                    display: block;
                    width: 100%;
                    font-family: $fontTitle;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 26px;
                    line-height: 31px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;

                    /* White */

                    color: #FFFFFF;
                    margin-top: 0;
                    margin-bottom: 20px;
                }

                .tiktok-text {
                    display: block;
                    width: 100%;
                    font-family: $fontText;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.005em;
                    color: #FFFFFF;
                    margin-bottom: 40px;
                    p {
                        margin: 0;
                    }
                }

                .tiktok-btn {
                    display: block;
                    width: 100%;
                    background-color: #E94359;
                    border-radius: 7px;
                    padding: 15px 50px;
                    text-align: center;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: #FFFFFF;
                }
            }
        }
    }

    .bloc-children-care {
        background: #F7F2F0;
        padding: 200px 20px 50px;
        padding-right: 0;

        @media (max-width: 764px) {
            padding: 40px 0;
        }
        
        .children-care-wrapper {
            .children-care-title {
                text-align: center;
            }

            .all-care-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                max-width: calc(994px + 80px);
                margin: 0 auto;
                margin-top: 100px;
                position: relative;
                padding: 60px 0 60px 70px;

                @media (max-width: 764px) {
                    padding: 30px 20px;
                    flex-wrap: wrap;
                }
                .fake-bg-white {
                    position: absolute;
                    width: 1252px;
                    height: 100%;
                    background-color: #fff;
                    top: 0;
                    left: 0;
                    z-index: 1;

                    @media (max-width: 764px) {
                        width: 100%;
                    }
                }

                .all-care-list-wrapper {
                    display: block;
                    width: 100%;
                    max-width: 464px;
                    position: relative;
                    z-index: 2;
                    

                    @media (max-width: 764px) {
                        max-width: 100%;
                        margin-bottom: 40px;
                    }
                    

                    .care-list-wrapper {
                        border-bottom: 2px dashed #262626;
                        .care-wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: baseline;
                            width: 100%;
                            margin-bottom: 30px;
                            
    
                            .care-description {
                                width: 100%;
                                max-width: 265px;
                                
                                .care-title {
                                    display: block;
                                    font-family: $fontText;
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 24px;
                                    /* identical to box height, or 150% */
    
                                    display: flex;
                                    align-items: center;
                                    letter-spacing: 0.005em;
    
                                    /* Light black */
    
                                    color: #262626;
                                    margin-top: 0;
                                    margin-bottom: 10px;
                                }
    
                                .care-text {
                                    display: block;
                                    font-family: $fontText;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 16px;
                                    display: flex;
                                    align-items: center;
                                    letter-spacing: 0.005em;
    
                                    color: #C9A79F;
                                    margin: 0;
                                }
                            }
    
                            .care-details {
                                display: flex;
                                justify-content: space-between;
                                align-items: flex-end;
                                width: 100%;
                                max-width: 139px;
    
                                .care-time {
                                    margin: 0;
                                }
    
                                .care-price {
                                    margin: 0;
                                }
    
                                .details-separator {
                                    border-top: 1px dashed #262626;
                                    width: 100%;
                                    margin-left: 10px;
                                    margin-right: 10px;
                                    margin-bottom: 3px;
                                }
                            }
                        }
                    }

                    .care-list-resume {
                        display: block;
                        width: 100%;
                        text-align: center;

                        font-family: $fontText;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.005em;
                        color: #262626;
                        margin-top: 40px;

                        p {
                            margin: 0;
                        }
                    }
                }

                .care-img {
                    display: block;
                    width: 100%;
                    max-width: 420px;
                    position: relative;
                    z-index: 2;

                    @media (max-width: 764px) {
                        max-width: 100%;
                    }
                    
                }
            }
        }
    }

    .bloc-children-formule {
        background: #F7F2F0;
        padding: 0 20px 100px;
        padding-left: 0;


        @media (max-width: 764px) {
            padding: 0;
            padding-bottom: 50px;
        }

        .children-formule-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: calc(994px + 80px);
            margin: 0 auto;
            position: relative;
            padding: 60px 60px 70px 0;

            @media (max-width: 764px) {
                padding: 30px 20px;
                flex-wrap: wrap;
            }

            .fake-bg-white {
                position: absolute;
                height: 100%;
                width: 1250px;
                right: 0;
                top: 0;
                background-color: #fff;
                z-index: 1;
            }
            
            .formule-img {
                display: block;
                width: 100%;
                max-width: 464px;
                position: relative;
                z-index: 2;

                @media (max-width: 764px) {
                    max-width: 100%;
                }
            }

            .all-formule-wrapper  {
                display: block;
                width: 100%;
                max-width: 420px;
                position: relative;
                z-index: 2;

                @media (max-width: 764px) {
                    max-width: 100%;
                }

                .list-wrapper {
                    .formule-wrapper {
                        margin-bottom: 40px;
                        padding-bottom: 40px;
                        border-bottom: 2px dashed #262626;

                        &:last-child {
                            margin-bottom: 0;
                            padding-bottom: 0;
                            border-bottom: none;
                        }
                        .formule-title {
                            display: block;
                            width: 100%;
                            font-family: $fontText;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 22px;
                            /* or 157% */
                            
                            display: flex;
                            align-items: center;
                            letter-spacing: 0.005em;
                            
                            /* Light black */
                            
                            color: #262626;
                            margin-top: 0;
                            margin-bottom: 10px;
                        }
                        .formule-text {
                            display: block;
                            width: 100%;
                            font-family: $fontText;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 22px;
                            /* or 157% */

                            display: flex;
                            align-items: center;
                            letter-spacing: 0.005em;

                            /* Light black */

                            color: #262626;
                            margin: 0;
                        }
                        .formule-list {
                            display: block;
                            width: 100%;
                            font-family: $fontText;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 22px;
                            /* or 157% */

                            display: flex;
                            align-items: center;
                            letter-spacing: 0.005em;

                            color: #C9A79F;
                            margin-top: 10px;
                            margin-bottom: 20px;

                            ul {
                                padding-left: 13px;
                                li {
                                    margin: 0;
                                }
                            }
                        }
                        .formule-price-time {
                            display: block;
                            width: 100%;
                            font-family: $fontText;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 26px;
                            /* identical to box height, or 144% */
                            
                            display: flex;
                            align-items: center;
                            text-align: center;
                            letter-spacing: 0.005em;
                            
                            /* Light black */
                            
                            color: #262626;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }


}