// --------------------------------------------------
// Hero
// --------------------------------------------------
.hero-home {
  position: relative;
  width: 100%;
  height: auto;
  background: $white;
  border-bottom: 5px solid #fff;

  .main-carousel {
    background: #fff;

    .carousel-cell {
      position: relative;
      width: 100%;
      max-width: 1125px;
      margin-right: 5px;
      margin-left: 5px;
      overflow: hidden;

      img{
        .main {
          -webkit-filter: grayscale(1); /* Safari 6.0 - 9.0 */
          filter: grayscale(1);
        }
      }

      &.is-selected {
        img{
          .main {
            -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
            filter: grayscale(0);
          }
        }
      }


      @media only screen and (max-width: 1349px) {
        max-width: 925px;
      }

      @media only screen and (max-width: 1099px) {
        max-width: inherit;
      }

      @media only screen and (max-width: 699px) {
        height: 340px;
      }
    }

    .carousel-cell-content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 4;

      padding: 45px;
      text-align: center;
      color: #fff;

      opacity: 0;
      transition: all 0.45s ease;



      overflow: hidden;

      @media only screen and (max-width: 767px) {
        padding: 45px 20px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgb(0, 0, 0);
        z-index: 5;
        opacity: .60;
      }

      * {
        position: relative;
        z-index: 6;
      }

      .separator-content-wrapper {
        height: 100%;
      }
    }

    .carousel-cell-title {
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      font-family: $fontTitleSerif;
      color: $light;
      letter-spacing: 0.055em;

      @media only screen and (max-width: 1349px) {
        top: 25px;
      }

      @media only screen and (max-width: 1099px) {
        top: 0;
        font-size: 18px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }

      @media only screen and (max-width: 699px) {
        font-size: 12px;
      }
    }

    .carousel-cell-text {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 0;
      transform: translate3d(-50%, -50%, 0);

      width: 100%;
      max-width: 800px;
      margin: 0;
      padding: 45px;
      font-family: $fontTextLight;
      font-size: 27px;
      line-height: 1.5;
      letter-spacing: 0.05em;
      color: #fff;
      text-align: center;
      // text-shadow: 0 1px 10px rgba(0, 0, 0, .35);

      @media only screen and (max-width: 1349px) {
        font-size: 24px;
        line-height: 1.3;
      }

      @media only screen and (max-width: 1099px) {
        font-size: 22px;
        line-height: 1.2;
      }

      @media only screen and (max-width: 767px) {
        font-size: 18px;
      }

       @media only screen and (max-width: 699px) {
        font-size: 16px;
        padding: 20px;
        margin-top: -10px;
       }
    }

    .site-btn {
      position: absolute;
      bottom: 75px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);

      padding: 25px 45px;

      color: #fff;
      border-color: #fff;
      font-size: 14px;

      font-family: $fontTitleSerif;
      letter-spacing: 0.055em;
      text-transform: uppercase;

      @media only screen and (max-width: 1349px) {
        bottom: 25px;
      }

      @media only screen and (max-width: 1099px) {
        bottom: 0;
      }

      @media only screen and (max-width: 767px) {
        padding: 15px 25px;
        font-size: 12px;
      }

      @media only screen and (max-width: 699px) {
        padding: 15px 15px;
      }

      &:hover {
        background: #fff;
        color: $colorAccent1;
      }
    }

    img.main {
      display: block;
      width: 100%;
      height: auto;

      @media only screen and (max-width: 699px) {
        transform: scale(2);
      }
    }
  }

  img.hero-pattern {
    position: absolute;
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
    width: 200px;
    height: auto;
    filter: none;
    z-index: 4;
  }
}

.carousel-cell.is-selected {

  img.main {
    -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
    filter: grayscale(0);
  }

  .carousel-cell-content {
    opacity: 1;
  }
}

.home-section {

}

// --------------------------------------------------
// Introduction
// --------------------------------------------------
.page-introduction {
  display: flex;
  padding: 5px 0 10px 0;

  .intro-content {
    width: 45%;
    padding: 125px 55px;
    padding-right: 175px;

    .regular-text {
      margin-bottom: 75px;
    }
  }

  .intro-image {
    width: 55%;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      display: none;
    }
  }
}

.home-diagram {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

// --------------------------------------------------
// Latest News
// --------------------------------------------------
.latest-news {
  width: 100%;
  padding: 75px 55px;
  background: $alternateWhite;
  text-align: center;

  @media only screen and (max-width: 1349px) {
    padding: 75px 45px;
  }

  @media only screen and (max-width: 1099px) {
    padding: 75px 25px;
  }

  @media only screen and (max-width: 767px) {
    padding: 75px 20px;
  }

  .medium-title {
    display: inline-block;
    margin: 0 auto;
    color: $grey;

    &:before,
    &:after {
      background: $grey;
    }
  }

  .latest-news-grid {
    position: relative;
    display: flex;
  }

  .cards-list-wrapper {
    width: 70%;

    .card-item {
      width: (97% / 2);
    }
  }

  .card-featured {
    position: absolute;
    top: 75px;
    right: 0;
    bottom: 0;

    width: 30%;
    margin: 0.5%;

    box-shadow: 0 0 20px 0 rgba(221, 221, 221, 1);
  }
}

// --------------------------------------------------
// Initiatives
// --------------------------------------------------
.initiatives-list {
  width: 100%;
  padding: 0 55px 75px;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 1349px) {
    padding: 0 45px 75px;
  }

  @media only screen and (max-width: 1099px) {
    padding: 0 25px 75px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 20px 25px;
  }

  .medium-title {
    display: inline-block;
    margin: 0 auto;
    color: $grey;

    @media only screen and (max-width: 767px) {
      font-size: 18px;
    }

    &:before,
    &:after {
      background: $grey;
    }
  }
}

// --------------------------------------------------
// Newsletter
// --------------------------------------------------
.newsletter-cta {
  display: flex;
  padding: 5px 0 10px 0;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  .newsletter-content {
    position: relative;
    width: 60%;
    padding: 75px 55px;
    padding-right: 175px;
    color: #fff;

    background: $colorAccent1;
    background-image: url('/img/site/img-ph1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;

    @media only screen and (max-width: 1349px) {
      padding: 75px 45px;
    }

    @media only screen and (max-width: 1099px) {
      padding: 75px 25px;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 35px 20px;
      text-align: center;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $colorAccent1;
      opacity: .60;

      z-index: 1;
    }

    .big-title {
      // max-width: 300px;
      line-height: 1.3;
      font-size: 26px;
      margin-bottom: 25px;

      @media only screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    .regular-text {
      margin-bottom: 35px;
      max-width: 400px;

      @media only screen and (max-width: 767px) {
        max-width: inherit;
      }
    }

    .big-title,
    .link-text-btn,
    .regular-text {
      position: relative;
      color: #fff;
      border-color: #fff;
      z-index: 1;
    }

    .site-btn {
      position: relative;
      color: #fff;
      border-color: #fff;

      z-index: 2;

      &:hover {
        background: #fff;
        color: $colorAccent1;
      }
    }
  }

  .newsletter-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: calc(100% - 60%);
    padding: 65px 55px;
    background: $alternateWhite;
    text-align: left;

    @media only screen and (max-width: 1349px) {
      padding: 65px 45px;
    }

    @media only screen and (max-width: 1099px) {
      padding: 65px 25px;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 35px 20px;
      text-align: center;

      .newsletter-component {
        width: 100%;
      }
    }

    .big-title {
      font-family: $fontTextLight;
      width: 100%;
      font-weight: 400;
      letter-spacing: 0;
      font-size: 32px;
      line-height: 1.3;

      @media only screen and (max-width: 767px) {
        font-size: 26px;
        padding: 0;
        margin: 0 0 25px 0;
      }

      img {
        width: 35px;
      }
    }

    .newsletter-form {
      display: flex;
      width: 100%;

      input {
        width: 100%;
        background: #fff;
        // border: 2px solid $colorAccent1;
        border: none;
        color: $colorAccent1;
        // border-radius: 3px;
        padding: 15px 20px;
        outline: none;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.02em;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        box-shadow: 0 0 35px 0 rgba(221, 221, 221, .8);


      }

      button {
        padding: 15px 35px;
        // border-radius: 3px;
        background: $colorAccent1;
        letter-spacing: 0.055em;
        font-family: $fontTitleSerif;
        font-size: 16px;
        color: #fff;
        border: 1px solid $colorAccent1;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        cursor: pointer;

        transition: all 0.35s ease;

        &:hover {
          background: $colorAccent3;
          color: #fff;
          border-color: $colorAccent3;
        }
      }
    }

    .hbspt-form form {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .hs-button,
      .hs-button.primary.large {
        position: relative;
        top: 2px;
        left: -3px;
        padding: 15px 35px;
        margin: 0;
        // border-radius: 3px;
        background: $colorAccent1;
        letter-spacing: 0.055em;
        font-family: $fontTitleSerif;
        font-size: 16px;
        color: #fff;
        border: 1px solid $colorAccent1;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        cursor: pointer;

        transition: all 0.35s ease;

        &:hover {
          background: $colorAccent3;
          color: #fff;
          border-color: $colorAccent3;
        }
      }

      .hs_error_rollup {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translate3d( 0, 100%, 0);
      }

      .hs-error-msgs.inputs-list {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translate3d( 0, 100%, 0);
        line-height: 1;
      }
    }
  }
}
