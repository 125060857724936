
// Cart floating
.visual-nav {
  .flickity-button {
    display: none !important;
  }
}

.floating-cart {
  position: fixed;
  top: 120px;
  right: 20px;
  z-index: 3;
  display: inline-block;
  width: 75px;
  height: 75px;
  cursor: pointer;
  transition: all .5s ease-in-out;

  @media (max-width: 1349px) {
    right: 40px;
  }

  @media (max-width: 1099px) {
    right: 20px;
  }

  &:hover {
    transform: scale(1.05);
  }

  .cart-wrapper {
    position: relative;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background: $dark;
    transition: all .5s ease-in-out;

    &.is-active {
      background: $colorAccent1;

      .cart-qty {
        color: $dark;
      }

      svg {
        path {
          fill: $dark;
        }
      }
    }

    .cart-qty {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      color: $white;
      font-size: 16px;
      font-weight: 600;
      transform: translate(-50%, -50%);
      transition: all .5s ease-in-out;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 36px;
      transform: translate(-50%, -58%);
      transition: all .5s ease-in-out;

      path {
        fill: $white;
        transition: all .5s ease-in-out;
      }
    }
  }
}

// Cart
.shop-cart {
  padding-top: 50px;
  padding-bottom: 100px;

  .cart-items {
    width: 100%;
    padding: 40px;
    margin-top: 50px;
    margin-bottom: 50px;
    border: 1px solid $dark;
    border-radius: 3px;
  }

  .items-product {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $dark;

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .product-visual {
      width: 200px;

      @media (max-width: 767px) {
        order: 1;
        width: 100px;
      }

      img {
        display: block;
        width: 100%;
        border-radius: 3px;
        border: 1px solid $colorAccent1;
      }
    }

    .product-content {
      padding-left: 20px;
      padding-right: 20px;

      @media (max-width: 767px) {
        order: 3;
        width: 100%;
        padding-top: 20px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .product-actions {
      margin-left: auto;

      @media (max-width: 767px) {
        order: 2;
        width: calc(100% - 100px);
        padding-left: 20px;
      }
    }

    .product-title {
      margin-top: 0;
    }

    .product-description {
      max-width: 500px;
    }

    .product-option,
    .product-option strong {
      color: $dark;
    }

    .product-qty {
      form {
        display: flex;
      }

      label {
        color: $dark;
        font-weight: 600;
      }

      input {
        width: 25px;
        margin-left: 10px;
        text-align: center;
      }

      button {
        display: flex;
        align-items: center;
        padding: 0;
        border: none;
        background: none;
        -webkit-appearance: none;
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
          pointer-events: none;

          path {
            fill: $lighter;
          }
        }

        svg {
          width: 20px;
          margin-left: 10px;
        }

        path {
          fill: #22bb33;
        }
      }
    }

    .product-price {
      color: $colorAccent1;
    }

    .product-remove {
      button {
        display: flex;
        align-items: center;
        padding: 0;
        color: #bb2124;
        font-weight: 600;
        border: none;
        background: none;
        -webkit-appearance: none;
        cursor: pointer;

        svg {
          width: 16px;
          margin-right: 5px;
        }

        path {
          fill: #d11a2a;
        }
      }
    }
  }

  .cart-pickup-delivery {
    max-width: 280px;
    margin: 0 auto;
    margin-right: 0;

    h2 {
      width: 100%;
      margin: 0;
    }

    hr {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-bottom: 0;
      margin-top: 10px;

      label {
        display: block;
        width: 100%;
        margin: 15px 0;
        color: $dark;

        strong {
          color: $dark;
        }
      }
    }
  }

  .cart-amounts {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 767px) {
      justify-content: flex-start;
    }

    .regular-text,
    .regular-text strong {
      color: $dark;
    }

    hr {
      margin-top: 30px;
    }
  }

  .cart-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
    text-align: center;

    p {
      color: $dark;
    }

    .site-btn {
      margin-top: 30px;
    }
  }
}

// Product details
.shop-product {
  padding-top: 50px;
  padding-bottom: 100px;

  .product-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 999px) {
      flex-direction: column;
    }
  }

  .product-visual {
    position: relative;
    width: 100%;
    max-width: 500px;

    @media (max-width: 1199px) {
      max-width: 400px;
    }

    @media (max-width: 999px) {
      max-width: 500px;
      margin: 0 auto;
    }

    .visual-preview {
      width: 100%;
    }

    .nav-thumb-container {
      width: 170px;
      height: 170px;
      padding: 10px;

      @media (max-width: 1199px) {
        width: 140px;
        height: 140px;
      }

      @media (max-width: 999px) {
        width: 100px;
        height: 100px;
        padding: 5px;
      }

      &.is-active {
        .nav-thumb {
          box-shadow: 0 0 8px 3px rgba($dark, .1);
        }
      }
    }

    .nav-thumb {
      width: 100%;
      border-radius: 4px;
      transition: all .3s ease-in-out;
    }
  }

  .product-details {
    width: calc(100% - 500px);
    padding-left: 100px;

    @media (max-width: 1199px) {
      width: calc(100% - 400px);
      padding-left: 80px;
    }

    @media (max-width: 999px) {
      width: 100%;
      padding-left: 0;
    }
  }

  .product-price {
    color: $colorAccent1;
  }

  .product-select-variant {
    position: absolute;
    top: -5000px;
    left: -5000px;
    opacity: 0;
    z-index: 0;
  }

  .variants-wrapper {
    display: flex;
    margin-top: 20px;
    margin-bottom: 30px;

    @media (max-width: 999px) {
      padding-top: 20px;
      padding-bottom: 20px;
      overflow-x: auto;
    }

    .product-variant {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100px;
      min-height: 100px;
      margin-right: 20px;
      font-weight: 600;
      background: $white;
      border-radius: 3px;
      box-shadow: 0 0 8px 3px rgba($dark, .1);
      cursor: pointer;

      @media (max-width: 999px) {
        min-width: 80px;
        min-height: 80px;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.is-active {
        border: 1px solid $dark;
      }

      &.is-disabled {
        border: none;
        color: $dark;
        background: rgba($dark, .15);
        box-shadow: none;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }

  .product-add-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .product-qty {
    margin-bottom: 20px;
    label {
      color: $dark;
      font-weight: 600;
    }

    input {
      width: 30px;
      padding-top: 5px;
      padding-bottom: 4px;
      text-align: center;
      font-weight: 600;
    }
  }
}

// Products listing
.shop-listing-wrapper {
  padding-top: 70px;
  padding-bottom: 70px;


  .shop-listing-container {
    @extend .site-max-width;


    .products-cards-listing {
      display: flex;
      flex-wrap: wrap;

      .product-card {
        display: block;
        width: calc((100% / 4) - (60px / 4));
        padding: 20px;
        margin-right: 20px;
        border: 1px solid $dark;
        transform: scale(1);
        transition: all 0.35s ease-in-out;
        margin-bottom: 35px;

        &:nth-child(4n) {
          margin-right: 0;
        }

        @media (max-width: 768px) {
          width: calc((100% / 3) - (40px / 3));

          &:nth-child(4n) {
            margin-right: 20px;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        @media (max-width: 375px) {
          width: calc((100% / 2) - (20px / 2));

          &:nth-child(4n) {
            margin-right: 20px;
          }

          &:nth-child(3n) {
            margin-right: 20px;
          }

          &:nth-child(2n) {
            margin-right: 0;
          }


        }

        &:hover {
          transform: scale(1.04);
          box-shadow: 0px 10px 15px rgba(38, 38, 38, 0.2);
        }


        .product-img {
          display: block;
          width: 100%;
        }

        .product-title {
          display: block;
          width: 100%;
          text-align: center;
          margin-top: 7px;
          margin-bottom: 0;

          font-family: $fontText;

          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 22px;


          color: #000000;
        }

        .product-price {
          display: block;
          width: 100%;
          text-align: center;
          margin-top: 7px;
          margin-bottom: 0;

          font-family: $fontText;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;

          color: #000000;
        }
      }
    }
  }
}


.shop-listing-filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 35px;
  border: 1px solid $dark;

  margin: 30px 20px 80px;

  @media (max-width: 1023px) {
    justify-content: space-between;
  }

  select {
    border: 1px solid $dark;
    border-radius: none;

    padding: 5px 30px 5px 15px;

    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 899px) {
      margin-right: 20px;
      max-width: 100%;
      margin-bottom: 12px;
    }

    @media (max-width: 699px) {
      width: 100%;
      margin-right: 0;
      max-width: 100%;
      margin-bottom: 12px;
    }
  }
}

// Order confirmation
.shop-confirmation {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;

  svg {
    width: 125px;
    margin-bottom: 20px;

    path {
      fill: #22bb33;
    }
  }

  .regular-text {
    color: $dark;
  }

  .site-btn {
    margin-top: 30px;
  }
}

.shop-payment {
  padding-top: 100px;
  padding-bottom: 100px;

  .shop-payment-part {
    @extend .site-max-width;
    padding-bottom: 50px;

    .heading-payment {
      width: 100%;
      padding-bottom: 80px;
      h2 {
        display: inline-block;
        font-family: $fontTitle;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 44px;
        text-align: left;

        color: #333333;
        margin: 0;

        border-bottom: 1px solid #000;
        padding-bottom: 20px;
      }
    }

    .payment-form {
      display: block;
      width: 100%;
    }

    .warning-quote-payment {
      font-family: $fontText;
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;

      color: #333333;
      margin-top: 0;
    }

    form {
      display: flex;
      flex-wrap: wrap;

      .billing-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        &.is-disabled {
          display: none;
        }

        .half-input-bill {
          width: calc(100% / 2 - 35px);
          margin-bottom: 30px;
          margin-right: 70px;

          &:nth-child(2n + 1) {
            margin-right: 0;
          }

          @media (max-width: 767px) {
            width: 100%;
            margin-right: 0;
          }
        }

      }

      input {
        height: 60px;
        border: 1px solid #000000;
        border-radius: 0;
        padding-left: 10px;
      }

      .half-input {
        width: calc(100% / 2 - 35px);
        margin-bottom: 30px;
        margin-right: 70px;

        &:nth-child(2n + 1) {
          margin-right: 0;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
        }
      }

      .third-input {
        width: calc((100% / 3) - (140px / 3));
        margin-bottom: 30px;
        margin-right: 70px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
        }
      }

      .shipping-billing-btn {
        width: 100%;
        display: block;

        .site-btn {
          max-width: 180px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;

          padding-top: 15px;
          padding-bottom: 15px;

        }
      }
    }

    .container {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 75px;
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      height: 60px;

      &.same-as-shipping {
        margin-bottom: 80px;
        padding-left: 95px;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      input:checked ~ .checkmark {
        background-color: $white;
        border: 1px solid $dark;
      }

      input:checked ~ .checkmark:after {
        display: block;
      }

      &:hover input ~ .checkmark {
        background-color: $white;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 60px;
        width: 60px;
        background-color: #eee;
        border: 1px solid $dark;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      .checkmark:after {
        left: 19px;
        top: 2px;
        width: 20px;
        height: 40px;
        border: solid $dark;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .warning-validate-address {
    font-family: $fontText;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    color: #333333;
    margin-top: 0;
  }
}
