// --------------------------------------------------
// Block Grid002
// --------------------------------------------------

.grid002 {
  padding: 60px 20px;
  display: flex;
  justify-content: center;

  .grid002-container {
    width: 100%;
    max-width: 1024px;

    &.fullwidth {
      max-width: 100%;
    }
  }

  .grid-heading-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }

    &.align-left {
      align-items: flex-start;

      .grid002-text {
        text-align: left;
      }
    }
    &.align-right {
      align-items: flex-end;

      .grid002-text {
        text-align: right;
      }
    }
  }

  .grid002-heading {
    @extend .big-title;
    margin: 0 0 20px;
    text-align: center;
  }

  .grid002-text {
    @extend .regular-text;
    text-align: center;
    max-width: 800px;

    p {
      margin: 0;
    }
  }

  .grid002-wrapper {
    display: flex;
    flex-direction: column;

    @media (max-width: 1023px) {
      flex-flow: row wrap;
    }

    @media (max-width: 767px) {
      display: block;
      padding-bottom: 0;
    }

    .grid002-title {
      @extend .medium-title;
      margin: 0 0 20px;
    }

    .grid002-desc {
      @extend .regular-text;
      width: 100%;

      p {
        margin: 0;
      }
    }
  }

  .grid002-row {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;

    @media (max-width: 767px) {
      display: block;
      width: 100%;
      box-shadow: none;
    }

    .grid002-row-img {
      position: relative;
      width: 100%;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -24px;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;
        border-bottom: 20px solid $white;
      }

      .picture, img {
        display: block;
        width: 100%;
      }
    }

    .grid002-row-content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 30px 60px;

      @media (max-width: 1699px) {
        padding: 20px;
      }

      .grid002-desc {
        p {
          margin: 0;
        }
      }
    }

    .grid002-row-element {
      display: flex;
      flex-direction: column;
      width: calc(100% / 3);
      text-align: center;

      @media (max-width: 1023px) {
        width: 50%;
      }

      @media (max-width: 767px) {
        float: left;
        width: 100%;
        padding: 0 20px;

        .grid002-desc {
          min-height: 0;
        }

        .grid002-title {
          min-height: 0;
        }

        &:last-child {
          margin-bottom: 45px;
        }
      }

      @media (max-width: 349px) {
        width: calc(90% - 10px);
      }

      &:nth-child(3n+2) {
        flex-direction: column-reverse;

        .grid002-row-img:before {
          bottom: initial;
          top: 0;
          transform: rotate(180deg);
        }
      }

      @media (max-width: 1023px) {
        &:nth-child(3n+2) {
          flex-direction: column;

          .grid002-row-img:before {
            top: initial;
            bottom: 0;
            transform: initial;
          }
        }

        &:nth-child(even) {
          flex-direction: column-reverse;

          .grid002-row-img:before {
            bottom: initial;
            top: 0;
            transform: rotate(180deg);
          }
        }
      }

      @media (max-width: 767px) {
        &:nth-child(even) {
          flex-direction: column;

          .grid002-row-img:before {
            bottom: 0;
            top: initial;
            transform: rotate(0deg);
          }
        }
      }

      &.light {
        background-color: white;

        .grid002-title {
          color: $dark;
        }

        .grid002-row-img:before {
          border-bottom-color: white;
        }
      }
      &.grey {
        background-color: $grey;

        .grid002-title {
          color: $dark;
        }

        .grid002-row-img:before {
          border-bottom-color: $grey;
        }
      }
      &.dark {
        background-color: $dark;

        .grid002-title {
          color: white;
        }

        .grid002-row-img:before {
          border-bottom-color: $dark;
        }
      }

      &.align-left {
        text-align: left;
      }
      &.align-right {
        text-align: right;
      }
    }
  }

  // Options
  &.grey {
    background-color: $grey;

    .grid002-row-img:before {
      border-bottom-color: $grey;
    }
  }
  &.dark {
    background-color: $dark;

    .grid002-heading, .grid002-title {
      color: white;
    }

    .grid002-row-img:before {
      border-bottom-color: $dark;
    }
  }
}
